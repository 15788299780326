import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const app = initializeApp({
  apiKey: "AIzaSyCWzAtgc_rAshNxJ2kAVjyFf8HyYD1yesY",
  authDomain: "amp-payment.firebaseapp.com",
  databaseURL: "https://amp-payment-default-rtdb.firebaseio.com",
  projectId: "amp-payment",
  storageBucket: "amp-payment.appspot.com",
  messagingSenderId: "1094356642080",
  appId: "1:1094356642080:web:b6696b428f5205746a8f45"
});

export const functions = getFunctions(app);
export const appCheck = initializeAppCheck(app, {

  provider: new ReCaptchaV3Provider('6LccRnwpAAAAAKoChvxir-lCyN8__kYAJt_IhBFn'),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});